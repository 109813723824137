import { mlAuto, mrAuto } from "assets/jss/material-kit-pro-react.jsx";
import testimonialsStyle from "ps-assets/jss/testimonialsStyle.jsx";

export default function RevampInstantBuy(theme) {
  return {
    ...testimonialsStyle,
    mlAuto,
    mrAuto,
    section1: {
      ...mlAuto,
      ...mrAuto,
      borderTop: `1px solid #24386F`,
      minHeight: `270px`,
      backgroundImage: `url(${require(`ps-assets/img/instant-buy-revamp/main-blue-bg.png`)})`,
      backgroundRepeat: `round`,
      backgroundSize: `contain`,
      borderBottom: `1px solid #DEE1EA`,
    },
    welcomeText: {
      fontSize: `24px`,
      color: `#FFF`,
      fontWeight: `500`,
      lineHeight: `25px`,
      paddingTop: `42px`,
      marginBottom: `20px`,
      margin: `0 0 10px`,
      fontFamily: `'Roboto'`,
      [theme.breakpoints.down(`xs`)]: {
        paddingTop: `15px`,
        color: `#364689`,
        fontSize: `16px`,
        fontWeight: `500`,
      },
    },
    title: {
      backgroundColor: `#24386f`,
      color: `#FFF`,
      fontSize: `15px`,
      padding: `10px 15px`,
      margin: `0px !important`,
      borderRadius: `4px 4px 0px 0px`,
      fontWeight: 500,
      '& div': {
        paddingLeft: `0px`,
        '& span': {
          paddingLeft: `0px`,
        },
      },
    },  
    titleTag: {
      backgroundColor: `#24386f`,
      color: `#FFF`,
      fontSize: `15px`,
      padding: `10px 15px`,
      margin: `0px`,
      borderRadius: `4px 4px 0px 0px`,
      fontWeight: `500`,
      fontFamily: `'Roboto'`,
    },
    displayFlex: {
      width: `100%`,
      display: `flex`,
      marginTop: `50px`,
      paddingRight: `15px`,
      paddingLeft: `15px`,
      marginRight: `auto`,
      marginLeft: `auto`,
      [theme.breakpoints.up(`xs`)]: {
        width: `100%`,
        display: `block`,
        marginTop: `50px`,
        paddingRight: `15px`,
        paddingLeft: `15px`,
        marginRight: `auto`,
        marginLeft: `auto`,
      },
    },
    detailsBox: {
      padding: `14px`,
      border: `8px solid #FFF`,
      marginRight: `3.5%`,
      width: `29.3%`,
      float: `left`,
      height:'100%',
      position: `relative`,
      minHeight: `1px`,
      '& p': {
        '& a': {
          fontSize: `18px`,
          fontFamily: `'Roboto' !important`,
          color: `#364689`,
          fontWeight: `500`,
        },
      },
      '& ul': {
        listStyle: `none`,
        paddingLeft: `20px`,
        '& li': {
          fontSize: `13px`,
          color: `#24386F`,
          padding: `6px 0px`,
          fontFamily: 'Roboto',
          lineHeight: `16px`,
          fontWeight: `400`,
          '&:before': {
            content: '""',
            width: `13px`,
            backgroundImage: `url(${require(`ps-assets/img/instant-buy-revamp/icons.png`)})`,
            position: `absolute`,
            height: `15px`,
            marginLeft: `-20px`,
            marginTop: `2px`,
          },
        },
      },
      "@media (max-width: 780px)": {
        width:'100%',
        height:'auto',
        marginTop:'10px',
        marginBottom: `60px`
        },
    },
    policySection: {
      minHeight: `71px`,
      backgroundColor: `#FFF`,
      borderRadius: `0px 0px 4px 4px`,
      marginBottom: `15px`,
      padding: `0px`,
      paddingBottom: `0px`,
    },
    customRadioBtn: {
      padding: `0px`,
      margin: `0px`,
      "& li": {
        color: `#AAAAAA`,
        display: `block`,
        position: `relative`,
        float: `left`,
        width: `33.33%`,
        minHeight: `71px`,
        "& input": { position: `absolute`, visibility: `hidden` },
        "& label": {
          fontFamily: `'Roboto'`,
          display: `block`,
          position: `relative`,
          margin: `0px`,
          cursor: `pointer`,
          color: `#0bb3e1`,
          fontSize: `14px`,
          // fontWeight: `normal`,
          textAlign: `center`,
          marginTop: `35px`,
          maxWidth: `100%`,
        },
      },
    },
    divCheck: {
      display: `block`,
      position: `absolute`,
      border: `3px solid #06B2E1`,
      borderRadius: `100%`,
      height: `16px`,
      width: `16px`,
      top: `18px`,
      left: `44%`,
      zIndex: `5`,
      cursor: `pointer`,
    },

    border: {
      borderLeft: `1px solid #E9EBF0`,
      borderRight: `1px solid #E9EBF0`,
    },
    detailsScript: {
      width: `100px`,
      backgroundImage: `url(${require(`ps-assets/img/instant-buy-revamp/img2.png`)})`,
      height: `60px`,
      float: `right`,
      marginTop: `-88px !important`,
      "@media (max-width: 700px)": {
      float: `left`,
      marginLeft:'42%',
      },
      "@media (max-width: 560px)": {
      float: `left`,
      marginLeft:'39%',
      },
      "@media (max-width: 430px)": {
        float: `left`,
        marginLeft:'35.2%',
        },
          "@media (max-width: 400px)": {
            float: `left`,
            marginLeft:'28.8%',
            }
    },
    indiv: {
      backgroundPosition: `-10px -8px`,
    },
    fmly: {
      backgroundPosition: `-5px -275px`,
    },
    parnt: {
      backgroundPosition: `-22px -172px`,
      width: `58px`,
      // marginTop:'78px',
      "@media (max-width: 700px)": {
        backgroundPosition: `-4px -172px`,
      width: `100px`,
    
      },
      "@media (max-width: 500px)": {
        backgroundPosition: `-4px -172px`,
        width: `100px`,
      }

      
    },
    downloadSprit: {
      content: " ",
      backgroundImage: `url(${require(`ps-assets/img/instant-buy-revamp/icons_new.png`)})`,
      float: `left`,
    },
    leftIcon: {
      backgroundPosition: `-101px -90px`,
      height: `35px`,
      width: `26px`,
      position: `absolute`,
      marginLeft: `-38px`,
      marginTop: `-18px`,
    },
    testimonialname: {
      [theme.breakpoints.up(`xs`)]: {
        marginTop: '-16px !important',
      },
    },  
    rightIcon: {
      backgroundPosition: `-101px -41px`,
      height: `35px`,
      width: `26px`,
      position: `absolute`,
      marginTop: `-20px`,
      marginLeft: `12px`,
    },
    textimonialContent: {
      textAlign: `center`,
      '& p': {
        fontSize: `14px`,
        fontFamily: `'Roboto' !important`,
        color: `#24386f`,
        fontStyle: `italic`,
        padding: `10px 30px 15px`,
        margin: `0px`,
        fontWeight: `400`,
      },
      [theme.breakpoints.up(`xs`)]: {
        '& p': {
          fontSize: `12px !important`,
        },
      },
    },  
    testimonial: {
      height: `200px`,
      // borderBottom: `1px solid #DEE1EA`,
      padding: `10px 15px`,
      background:'white',
      textAlign: `center`,
    },
    testimonialtitle: {
      textAlign: `center`,
      fontSize: `18px`,
      color: `#364689`,
      fontWeight: `600`,
      paddingTop: `15px`,
      margin: `0px`,
    },
  
    footerHolder: {
      width: `100%`,
      height: `auto`,
      paddingBottom: '2%',
    marginLeft: '10%',
    marginRight: '10%',
    paddingTop: '2%',
    borderTop: `1px solid #DEE1EA`,
      overflow: `hidden`,
      fontSize: `0.5em`,
      textAlign: `center`,
      color: `#838383`,
      lineHeight: `12px`,
      
    },
    section3: {
      backgroundColor: `white`,
    },
    carouselContainer: {
      paddingLeft:'3%',
      paddingRight:'3%',
      [theme.breakpoints.up(`sm`)]: {
        width: `100%`,
      },
    },
    section4: {
      backgroundColor: `white`,
      "@media (max-width:1060px)": {
        marginTop:'-0.8%'
      },
      
      "@media (max-width:850px)": {
        marginTop:'-1.8%'
      },
      
      "@media (max-width:750px)": {
        marginTop:' -2.5%'
      },
      "@media (max-width:690px)": {
        marginTop:' -3%'
      },
      "@media (max-width:636px)": {
        marginTop:' -3.4%'
      },
     
      "@media (max-width:500px)": {
        marginTop:'-4%'
      },
      
      "@media (max-width:350px)": {
        marginTop:'-6%'
      },
    },
    footer: {
      width: `100%`,
      margin: `0`,
      borderTop: `1px solid #DEE1EA`,
      padding: `15px 0px`,
      textAlign: `center`,
      fontSize: `11px`,
      background: `#F2F2F2`,
      color: `#94969e`,
    },
    Downloadsprit: {
      backgroundImage: `url(${require(`ps-assets/img/instant-buy-revamp/icons_new.png`)})`,
      float: `left`,
    },
    section2: {
      minHeight: `345px`,
      backgroundColor: `#F4F4F4`,
      padding: `30px 0px`,
      borderBottom: `1px solid #DEE1EA`,
      display: `block`,
      boxSizing: `border-box`,
    },
    customContainer: {
      paddingRight: `15px`,
      paddingLeft: `15px`,
      marginRight: `auto`,
      marginLeft: `auto`,
    },
    lefticon: {
      backgroundPosition: `-101px -90px`,
      height: `35px`,
      width: `26px`,
      position: `absolute`,
      marginLeft: `-36px`,
      marginTop: `-18px`,
    },  
    righticon: {
      backgroundPosition: `-101px -41px`,
      height: `35px`,
      width: `26px`,
      position: `absolute`,
      marginTop: `-20px`,
      marginLeft: `12px`,
    },  
    "@keyframes slideActive": {
      "0%": {
        transform: "translateX(0%)",
      },
      "100%": {
        transform: "translateX(-200)",
      },
    },
    "@keyframes slideInActive": {
      "0%": {
        transform: "translateX(0%)",
      },
      "100%": {
        transform: "translateX(-200)",
      },
    },
    active: {
      display: `block !important`,
      position: `relative`,
      animation: `slideActive 1000ms ${theme.transitions.easing.easeInOut}`,
    },
    inActive: {
      animation: `slideInActive 1000ms ${theme.transitions.easing.easeInOut}`,
      display: `none !important`,
    },  
    carouselIndicators: {
      width: `60%`,
      textAlign: `center`,
      listStyle: `none`,
      paddingLeft: `0`,
      marginLeft: `auto`,
      marginRight: `auto`,
      marginTop: `-3%`,
      '& li': {
        display: `inline-block !important`,
        width: `13px`,
        height: `13px`,
        margin: `0px`,
        textIndent: `-999px`,
        cursor: `pointer`,
        backgroundColor: `rgb(36, 56, 111)`,
        border: `1px solid #24386f`,
        borderRadius: `10px`,
        marginLeft: `5px`,
      },
      "@media (max-width:500px)": {
        paddingTop:'-1%',
        background:'white',
        width:'100%',
        paddingLeft:'inherit',
        paddingRight:'inherit'
        
    
      },
      "@media (max-width: 378px)": {
        background:'white',
        width:'100%',
        paddingLeft:'inherit',
        paddingRight:'inherit',
        paddingTop:'15%',
      },
      
    },
  
    positionofContent:{
    // marginTop:'78px',
  "@media (max-width: 700px)": {
    textAlign:'left'

  },
  "@media (max-width: 500px)": {
    textAlign:'left'
  }
    },
    logoContainer: {
      backgroundColor: `white`,
      "& img": {
        maxWidth: `none`,
        marginLeft: `8%`,
        marginBottom: `1%`,
      },
    },
  };
}
