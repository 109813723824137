import React, { useEffect } from "react";
import RevampInstantBuy from "ps-assets/jss/RevampInstantBuyStyles.jsx";
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout.jsx";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import root, { clearInterval, setInterval } from "window-or-global";
import { Helmet } from "react-helmet";
// import Hidden from '@material-ui/core/Hidden';
import logo from "ps-assets/img/logo.png";
import testimonialTitle from "ps-assets/img/testimonialTitle.jpg";
import { browserWindow,browserDocument } from "util/localStorageHelper";

let url = process.env.BASE_URL;
var interval, globalClasses;
const transitionTime = 3000;
const testimonialContainer = `testimonial-container`;
const testimonials = [
  {
    summary: `I have claimed my premium once, when my daughter fell ill and her hospital bill came up to Rs. 25,000 and I received it in less than 2 hours.`,
    title: `Gurvinder Singh`,
  },
  {
    summary: `I have had this policy for the past 8-10 years. However, in the initial 2-3 years, I underwent a bypass surgery. The expenditure was about Rs. 2.5-3 lakhs. I received help from Star during my surgery and recovery. And my faith in the company has increased a lot. Today, all my family members have this policy.`,
    title: `Kamal`,
  },
  {
    summary: `I work for a tour company. My father was hospitalized thrice in the past four years with Star’s policy and all the bills were settled cashless.`,
    title: `Dileep`,
  },
  {
    summary: `Thank you very much for your sparkling action. I really appreciate your service. My interaction with Star Health was quite effective. They informed processes and replied to all my queries well. Due to that I could renew my mother health insurance policy online without any hassles.`,
    title: `Abhilash N`,
  }
];
const searchString = browserWindow.location.search;
const req = searchString && searchString.split(`?`)[1];
let utmString = ``;
req &&
  req.split(`&`).map((e) => {
    e = e.split(`=`);
    utmString += `&${e[0]}=${e[1]}`;
  });
const redirectUrls = [
  `/customerportal/instantbuy?type=comprehensive&adults=1&policyCover=500000&dob=&year=1&name=&mobNo=&email=&policyCoverOptions=[{%22display%22:%221%20Crore%22,%22value%22:%2210000000%22},{%22display%22:%2275%20Lakhs%22,%22value%22:%227500000%22},{%22display%22:%2250%20Lakhs%22,%22value%22:%225000000%22},{%22display%22:%2225%20Lakhs%22,%22value%22:%222500000%22},{%22display%22:%2220%20Lakhs%22,%22value%22:%222000000%22},{%22display%22:%2215%20Lakhs%22,%22value%22:%221500000%22},{%22display%22:%2210%20Lakhs%22,%22value%22:%221000000%22},{%22display%22:%227.5%20Lakhs%22,%22value%22:%22750000%22},{%22display%22:%225%20Lakhs%22,%22value%22:%22500000%22}]&productId=4&instantbuy=true&token=`,
  `/customerportal/instantbuy?type=comprehensive&adults=2&kids=0&policyCover=500000&dob=&year=1&name=&mobNo=&email=&policyCoverOptions=[{%22display%22:%221%20Crore%22,%22value%22:%2210000000%22},{%22display%22:%2275%20Lakhs%22,%22value%22:%227500000%22},{%22display%22:%2250%20Lakhs%22,%22value%22:%225000000%22},{%22display%22:%2225%20Lakhs%22,%22value%22:%222500000%22},{%22display%22:%2220%20Lakhs%22,%22value%22:%222000000%22},{%22display%22:%2215%20Lakhs%22,%22value%22:%221500000%22},{%22display%22:%2210%20Lakhs%22,%22value%22:%221000000%22},{%22display%22:%227.5%20Lakhs%22,%22value%22:%22750000%22},{%22display%22:%225%20Lakhs%22,%22value%22:%22500000%22}]&productId=4&instantbuy=true&token=`,
  `/customerportal/instantbuy?type=redcarpet&adults=1&policyCover=500000&dob=&year=1&policyType=Individual&name=&mobNo=&email=&policyCoverOptions=[{%22display%22:%2225%20Lakhs%22,%22value%22:%222500000%22},{%22display%22:%2220%20Lakhs%22,%22value%22:%222000000%22},{%22display%22:%2215%20Lakhs%22,%22value%22:%221500000%22},{%22display%22:%2210%20Lakhs%22,%22value%22:%221000000%22},{%22display%22:%227.5%20Lakhs%22,%22value%22:%22750000%22},{%22display%22:%225%20Lakhs%22,%22value%22:%22500000%22},{%22display%22:%224%20Lakhs%22,%22value%22:%22400000%22},{%22display%22:%223%20Lakhs%22,%22value%22:%22300000%22},{%22display%22:%222%20Lakhs%22,%22value%22:%22200000%22},{%22display%22:%221%20Lakh%22,%22value%22:%22100000%22}]&productId=7&instantbuy=true&token=`,
];

const radio_options = [
  { id: "f-option", title: "Myself" },
  { id: "s-option", title: "My Family" },
  { id: "t-option", title: "My Parents" },
];

function redirectToPortal(redirectURL, target) {
  localStorage.setItem(`sessionalive`, true);
  root.open(redirectURL, target);
}

function setCustomInterval(time) {
  interval = setInterval(changeCard, time);
}

function changeCard(currentIndex) {
  const classes = globalClasses;
  function toggleActive(element) {
    element.classList.toggle(classes.active);
    element.classList.toggle(classes.inActive);
  }
  let testimonialContainerElement = browserDocument.getElementById(
    testimonialContainer
  );
  if (testimonialContainerElement && testimonialContainerElement.children) {
    testimonialContainerElement = testimonialContainerElement.children;
  } else {
    testimonialContainerElement = [];
  }
  for (let i = 0; i < testimonialContainerElement.length; i++) {
    if (testimonialContainerElement[i].classList.contains(classes.active)) {
      toggleActive(testimonialContainerElement[i]);
      if (currentIndex && i < testimonialContainerElement.length - 1) {
        clearInterval(interval);
        setCustomInterval(transitionTime);
        toggleActive(testimonialContainerElement[currentIndex]);
      } else {
        if (i < testimonialContainerElement.length - 1)
          toggleActive(testimonialContainerElement[i + 1]);
        else toggleActive(testimonialContainerElement[0]);
      }
      break;
    }
  }
}


function GetTestimonials() {
  const classes = globalClasses;
  useEffect(() => setCustomInterval(transitionTime), []);
  return (
    <>
      <div
        id={testimonialContainer}
        style={{textAlign:'center',height:'200px'}}
        className={classNames(
          classes.carouselContainer,
          classes.mlAuto,
          classes.mrAuto
        )}>
          {/* <img src={testimonialTitle} /> */}
        {testimonials.map((testimonial, index) => (
          <GridContainer key={index}
            className={`${classes.testimonial} ${
              index === 0 ? classes.active : classes.inActive
            }`}>
           <img src={testimonialTitle} />
            <div
              className={classNames(
                classes.textCenter,
                classes.textimonialContent
              )}>
              <p>
                <i
                  className={`${classes.Downloadsprit} ${classes.lefticon}`}></i>
                {testimonial.summary}
                <i
                  className={`${classes.Downloadsprit} ${classes.righticon}`}></i>
              </p>
              <p className={classes.testimonialname}>{testimonial.title}</p>
              <br />
            </div>
          </GridContainer>
        ))}
      </div>
      <ol
        className={classNames(
          classes.carouselIndicators,
          classes.testimonialIndicator
        )}>
        {testimonials.map((content, index) => (
          <li key={content} className={classes.active} onClick={() => changeCard(index)}></li>
        ))}
      </ol>
    </>
  );
}


function InstantBuyRevamp({ classes }) {
  globalClasses = classes;
  return (
    <InstantBuyLayout noHeader={true} noFooter={true} style={{background:'white'}}>
      <Helmet key="helmetTags">
        <title>
          Instant Buy - Health Insurance Policy Online | StarHealth.in
        </title>
        <link rel="canonical" href={url + `instant-buy`} />
        <meta
          name="title"
          content="Instant Buy - Health Insurance Policy Online | StarHealth.in"
        ></meta>
        <meta
          name="twitter:title"
          content="Instant Buy - Health Insurance Policy Online | StarHealth.in"
        ></meta>
        <meta
          name="description"
          content="Explore and Buy Health Insurance Policy Online Immediately. You can Buy the Health Insurance Policy for you or to your Family or to your Parents Online."
        ></meta>
        <meta
          name="keywords"
          content="Buy Online,Buy Insurance Online,Buy StarHealth Insurance Policy, Online Health Insurance,Health Insurance Portal,Purchase Health Insurance Online,StarHealth Retail,Insurance Sale Online"
        ></meta>
        <meta property="og:title" content="Instant Buy - Health Insurance Policy Online | StarHealth.in" />
        <meta property="og:description" content="Explore and Buy Health Insurance Policy Online Immediately. You can Buy the Health Insurance Policy for you or to your Family or to your Parents Online." />
        <meta property="og:url" content={url + `instant-buy`}/>
        <meta name="Instant Buy - Health Insurance Policy Online | StarHealth.in" />
        <meta name="twitter:description" content="Explore and Buy Health Insurance Policy Online Immediately. You can Buy the Health Insurance Policy for you or to your Family or to your Parents Online." />
        <meta property="twitter:url" content={url + `instant-buy`} />
      </Helmet>
      <div className={classes.logoContainer}>
        <img src={logo} alt="Star Health" />
      </div>
      <GridContainer className={classes.section1} md={12}>
        <GridItem
          className={classNames(classes.mlAuto, classes.mrAuto)}
          md={5}
          sm={6}
        >
          <p className={classes.welcomeText}>
            An insurance plan from Star Health Insurance is perfect to protect
            you, your family or your parents
          </p>
          <h4 className={classes.titleTag}>I need insurance for</h4>
          <div className={classes.policySection}>
            <ul className={classes.customRadioBtn}>
              {radio_options.map((ele, index) => (
                <li
                  key={`options-${index}`}
                  className={
                    index != 0 && index != radio_options.length - 1
                      ? classes.border
                      : " "
                  }
                >
                  <input
                    onClick={() =>
                      redirectToPortal(`${redirectUrls[index]}${utmString}`,`_self`)
                    }
                    type="radio"
                    id={ele.id}
                    name="selector"
                  />
                  <label
                    htmlFor={ele.id}
                    onClick={() =>
                      redirectToPortal(`${redirectUrls[index]}${utmString}`,`_self`)
                    }
                  >
                    {ele.title}
                  </label>
                  <div
                    onClick={() =>
                      redirectToPortal(`${redirectUrls[index]}${utmString}`,`_self`)
                    }
                    className={classes.divCheck}
                  ></div>
                </li>
              ))}
            </ul>
            <div className="clearfix"></div>
          </div>
        </GridItem>
      </GridContainer>
      {/* <Hidden smDown> */}
          {/* <GridContainer className={classes.section2} md={12}>
            <GridContainer
              className={classNames(
                classes.customContainer,
                classes.mlAuto,
                classes.mrAuto
              )}
              md={11}>
              <div className={classes.displayFlex}>
                {[
                  {
                    divClass: `indiv`,
                    title: `Myself`,
                    content: [
                      `For Individuals aged between 18 to 65 years.`,
                      `No capping on room rent - Treatment at Single Standard A/C room`,
                      `Hospital cash benefits`,
                      `All day care procedures are covered`,
                      `Cost of health check up for every claim free year.`,
                    ],
                  },
                  {
                    divClass: `fmly`,
                    title: `My Family`,
                    content: [
                      `Affordable Health Insurance cover on a floater basis`,
                      `Covers Dental / ophthalmic treatments on OPD basis`,
                      `Upto 100% Increase in Sum Insured upon a claim free renewal`,
                      `Benefits include Air ambulance facility and availing Second medical opinion`,
                      `No capping on room rent - Treatment at Single A/C room`,
                    ],
                  },
                  {
                    divClass: `parnt`,
                    title: `My Parents`,
                    content: [
                      `For Senior citizens aged between 60 to 75 years`,
                      `No pre-insurance medical test required`,
                      `Covers pre-existing diseases from the second year onwards`,
                      `Medical Consultations as an Out Patient in a Network Hospital`,
                      `Guaranteed lifetime renewals`,
                    ],
                  },
                ].map((item, itemIndex) => (
                  <div
                    className={`${classes.detailsBox}
                    ${itemIndex>0 && classes.positionofContent}
                    `
                  }
                    
                    key={`content-${itemIndex}`}>
                    <div
                      className={classNames(
                        classes.detailsScript,
                        classes[item.divClass]
                      )}
                    />
                    <p>
                      <a href={redirectUrls[itemIndex]}>{item.title}</a>
                    </p>
                    <ul>
                      {item.content.map((line, lineIndex) => (
                        <li key={`line-${itemIndex}-${lineIndex}`}>{line}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </GridContainer>
          </GridContainer> */}
        {/* </Hidden> */}
        {/* <Hidden mdUp> */}
          <GridContainer className={classes.section2} md={12}>
            <GridContainer
              className={classNames(
                classes.customContainer,
                classes.mlAuto,
                classes.mrAuto
              )}
              md={11}>
              <div className={classes.displayFlex}>
                {[
                  {
                    divClass: `indiv`,
                    title: `Myself`,
                    content: [
                      `For Individuals aged between 18 to 65 years.`,
                      `No capping on room rent - Treatment at Single Standard A/C room`,
                      `Hospital cash benefits`,
                      `All day care procedures are covered`,
                      `Cost of health check up for every claim free year.`,
                    ],
                  },
                  {
                    divClass: `fmly`,
                    title: `My Family`,
                    content: [
                      `Affordable Health Insurance cover on a floater basis`,
                      `Covers Dental / ophthalmic treatments on OPD basis`,
                      `Upto 100% Increase in Sum Insured upon a claim free renewal`,
                      `Benefits include Air ambulance facility and availing Second medical opinion`,
                      `No capping on room rent - Treatment at Single A/C room`,
                    ],
                  },
                  {
                    divClass: `parnt`,
                    title: `My Parents`,
                    content: [
                      `For Senior citizens aged between 60 to 75 years`,
                      `No pre-insurance medical test required`,
                      `Covers pre-existing diseases from the second year onwards`,
                      `Medical Consultations as an Out Patient in a Network Hospital`,
                      `Guaranteed lifetime renewals`,
                    ],
                  },
                ].map((item, itemIndex) => (
                  <div
                    className={`${classes.detailsBox} ${itemIndex>0 && classes.positionofContent}`}
                    key={`content-${itemIndex}`}>
                      <div style={{width:'100%', textAlign:'center'}}>
                    <div
                      className={classNames(
                        classes.detailsScript,
                        classes[item.divClass]
                      )}
                    />
                    </div>
                    <p>
                      <a href={redirectUrls[itemIndex]}>{item.title}</a>
                    </p>
                    <ul>
                      {item.content.map((line, lineIndex) => (
                        <li key={`line-${itemIndex}-${lineIndex}`}>{line}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </GridContainer>
          </GridContainer>
        {/* </Hidden> */}
        <span style={{background:'white'}}>
        <GetTestimonials />
        </span>
        {/* <GridContainer
        className={classNames(classes.section3, classes.mlAuto, classes.mrAuto)}
        md={12}
      > */}
        {/* {getTestimonials()} */}
      {/* </GridContainer> */}
      <GridContainer className={classes.section4} md={12}>
        <div className={classes.footerHolder}>
          REGISTERED &amp; CORPORATE OFFICE: NO: 1, NEW TANK STREET,
          VALLUVARKOTTAM HIGH ROAD, NUNGAMBAKKAM, CHENNAI - 600034.
          <br />
          CIN NO: L66010TN2005PLC056649.
          <br />
          IRDAI REGISTRATION NO: 129.
          <br />
          INSURANCE IS THE SUBJECT MATTER OF SOLICITATION.
          <br />
          FOR MORE DETAILS ON RISK FACTORS, TERMS AND CONDITIONS PLEASE READ THE
          SALES BROCHURE CAREFULLY BEFORE CONCLUDING A SALE.
          <br />
          IRDAI CLARIFIES TO PUBLIC THAT
          <br />
          * IRDAI OR ITS OFFICIALS DO NOT INVOLVE IN ACTIVITIES LIKE SALE OF ANY
          KIND OF INSURANCE OR FINANCIAL PRODUCTS NOR INVEST PREMIUMS.
          <br />
          * IRDAI DOES NOT ANNOUNCE ANY BONUS.
          <br />
          * PUBLIC RECEIVING SUCH PHONE CALLS ARE REQUESTED TO LODGE A POLICE
          COMPLAINT ALONG WITH DETAILS OF PHONE CALL, NUMBER.
          <br />* SHAI/17-18/Online/LP/BuyOnline/001
        </div>
      </GridContainer>
      <div className={classes.footer}>
        Copyright ©2017. Star Insurance. All rights reserved{" "}
      </div>
    </InstantBuyLayout>
  );
}

InstantBuyRevamp.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(RevampInstantBuy)(InstantBuyRevamp);
